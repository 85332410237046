
import { Prop } from 'vue-property-decorator';
import { Options, Vue } from 'vue-class-component';

@Options({})
export default class EditCompositionPercentageModal extends Vue {

    @Prop() name: string;
    @Prop() actualPercentage: number;
    @Prop() saveCallback: (newPercentage: number) => void;

    localPercentage: number = 0;

    created() {
        this.localPercentage = this.actualPercentage;
    }

    get disabled(){
        return this.localPercentage == 0 || this.localPercentage >= 100;
    }

    save() {
        this.saveCallback(this.localPercentage);
    }
}
