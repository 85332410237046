
import { Options, Vue } from 'vue-class-component';
import { FootwearEstimationClient } from '@/services/Services';
import EditTotalWeightModal from './modals/editTotalWeightModal.vue';
import EditCompositionPercentageModal from './modals/editCompositionPercentageModal.vue';
import * as OM from '@/Model';

@Options({
    components: {
    }
})
export default class ComponentsWeights extends Vue {

    estimationIdentifier: string = "";

    footwearData: OM.FootwearData = new OM.FootwearData();
    footwearComposition: OM.SelectedComposition = new OM.SelectedComposition();

    loaded: boolean = false;
    
    created() {
        this.estimationIdentifier = this.$route.params.estimationIdentifier.toString();
        this.init();
    }

    init() {
        FootwearEstimationClient.getEstimationFootwearData(this.estimationIdentifier)
        .then(x => {
            this.footwearData = x;
            this.getFootwearComposition(false);
        })
    }

    get formattedData() {
        let dataWithIndent = this.footwearComposition.percentageCompositionValues.map((item) => {
            let indentName = item.parentName ? `-- ${item.name}` : item.name;
                return {...item, indentName};
        });
        return dataWithIndent;
    }

    getFootwearComposition(getDefault: boolean) {
        FootwearEstimationClient.getFootwearComposition(this.footwearData.garment.identifier, this.estimationIdentifier, getDefault)
        .then(x => {
            this.footwearComposition = x;

            setTimeout(() => {
                this.loaded = true;
            }, 100);
        })
    }

    openEditWeightModal() {
        this.$opModal.show(EditTotalWeightModal, {
            actualTotalWeight: this.footwearComposition.totalWeight,
            saveCallback: (newWeight: number) => {
                FootwearEstimationClient.updateTotalWeight(this.estimationIdentifier, newWeight)
                .then(x => {
                    this.$opModal.closeLast();
                    this.init();
                })
            }
        })
    }

    openEditCompositionPercentageModal(name: string, percentage: number) {
        var realName = name.indexOf("--") == -1 ? name : name.substring(3);

        this.$opModal.show(EditCompositionPercentageModal, {
            name: realName,
            actualPercentage: percentage,
            saveCallback: (newPercentage: number) => {
                var model = new OM.FootwearEstimationUpdatePercentageCompositionValueVM();
                model.identifier = this.estimationIdentifier;
                model.name = realName;
                model.percentage = newPercentage;

                FootwearEstimationClient.updatePercentageCompositionValue(model)
                .then(x => {
                    this.$opModal.closeLast();
                    this.init();
                })
            }
        })
    }
}
