
import { Prop, Watch } from 'vue-property-decorator';
import { Options, Vue } from 'vue-class-component';

@Options({})
export default class EditTotalWeightModal extends Vue {

    @Prop() actualTotalWeight: number;
    @Prop() saveCallback: (newTotalWeight: number) => void;

    localWeight: number = 0;

    created() {
        this.localWeight = this.actualTotalWeight;
    }

    get disabled(){
        return this.localWeight == 0;
    }

    save() {
        this.saveCallback(this.localWeight);
    }
}
